class ScrollHideHeader {
  constructor() {
    this.stickyTitle = document.querySelector(".header__top-bar__home");
    this.anchorTitle = document.querySelector(".anchor-section__headline");

    if (this.stickyTitle && this.anchorTitle) {
      this.init();
    }
  }

  init() {
    this.toggleStickyTitle = this.toggleStickyTitle.bind(this);

    // Initial check with delay
    setTimeout(() => {
      this.toggleStickyTitle();
      // Make sure the sticky title is visible initially
      this.stickyTitle.style.display = "flex";
    }, 100);

    // Set up event listeners
    window.addEventListener("scroll", this.toggleStickyTitle);
    window.addEventListener("resize", this.toggleStickyTitle);

    // Additional check after all resources have loaded
    window.addEventListener("load", () => {
      setTimeout(this.toggleStickyTitle, 100);
    });
  }

  toggleStickyTitle() {
    if (!this.anchorTitle) {
      return;
    }

    const anchorRect = this.anchorTitle.getBoundingClientRect();
    const anchorTop = anchorRect.top;

    // Hide the sticky title when the top of the anchor title reaches the top of the viewport
    if (anchorTop <= 0) {
      this.stickyTitle.style.display = "none";
    } else {
      this.stickyTitle.style.display = "flex";
    }
  }
}

export default ScrollHideHeader;
