import SmoothScroll from 'smooth-scroll';


class SmoothScrolling {
  constructor() {


    this.navItems       = Array.from(document.querySelectorAll('.on-page-nav__item a'));
    this.menuBar        = document.querySelector('.on-page-nav__list');
    this.sections       = Array.from(document.querySelector('main').children);
    this.scrollSections = [];

    this.sections.forEach(section => {
        if (section.id != '') {
            this.scrollSections.push(section);
        }
    });

    if (this.navItems.length > 0) {
        this.events();   
    }

    // Smooth Scroll Library
    if (document.querySelector( 'a[href*="#"]' )) {
      // Init SmoothScroll
      var smoothScroll = new SmoothScroll('a[href*="#"]', {
        speed: 200,
        offset: 100,  
        updateURL: true,
      });
    } 

  }

  events() {    
    this.sectionInView();
  }

  sectionInView() {

    let options = {
        rootMargin:     '-100px 0px -50% 0px',
        threshold:      '0'
    }

    const sectionObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            
            if (entry.isIntersecting) {
              this.navItems.forEach(navItem => {
                  navItem.classList.remove('active');
              });   

              // if section has id
              if (entry.target.id) {
                  // update the URL hash
                  if (window.history.pushState) {
                      let urlHash = "#" + entry.target.id;
                      window.history.pushState(null, null, urlHash);

                      let activeMenuItem = document.querySelector(`[data-anchor="${entry.target.id}"]`);
                      if (activeMenuItem) {
                        activeMenuItem.classList.add("active"); 
                        this.scrollToMenuItem(activeMenuItem);
                      }
                      
                  }
              }
                
            } 


          })
        }, options
    )

    this.scrollSections.forEach(section => {
        sectionObserver.observe(section);
    }); 

  }

    scrollToMenuItem(menuItem) {
        let pageMargin          = getComputedStyle(document.documentElement).getPropertyValue('--mg-page').replace(/[a-z]/gi, '') 
        let pageMarginPx        = pageMargin * 16;
        let scrollToOffset      = menuItem.offsetLeft;
        let newScrollPosition   = scrollToOffset - pageMarginPx; 
        this.menuBar.scrollLeft = newScrollPosition;
    }
}

export default SmoothScrolling