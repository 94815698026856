import Plyr from 'plyr';

class VideoPlayer {
    constructor() {
        if (document.querySelector(".video-player")) {
            const players = Array.from(document.querySelectorAll('.video-player')).map((player) => new Plyr(player));
        }
    }
}
  
export default VideoPlayer
  