// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';

class TextMediaSlider {
    constructor() {
      if (document.querySelector(".text-media-slider")) {
      // Actually init Swiper:
      const swiper = new Swiper('.text-media-slider', {
        modules: [Navigation, Pagination],
        direction: 'horizontal',
        centeredSlides: true,
        slidesPerView: "1",
        autoHeight: true,
        draggable: true,
        grabCursor: true,
        keyboard: true,
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // type: 'bullets'
        },
        breakpoints: {
          992: {      
              autoHeight: false,
          },
      },

        observer: true,  
        observeParents: true,

      });
    }
  }
}

export default TextMediaSlider