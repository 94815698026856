import "./css/style.scss";

// Modules/Classes
import MobileMenu from "./js/modules/MobileMenu";
import VideoPlayer from "./js/modules/VideoPlayer";
import ImageSlider from "./js/modules/ImageSlider";
import TextMediaSlider from "./js/modules/TextMediaSlider";
import SmoothScrolling from "./js/modules/SmoothScrolling";
import HomeViewMoreBtn from "./js/modules/HomeViewMoreBtn";
import ViewMoreBtn from "./js/modules/ViewMoreBtn";
import ScrollHideHeader from "./js/modules/ScrollHideHeader";
import AjaxNews from "./js/modules/AjaxNews";
import ViewMoreBtnAnchorSection from "./js/modules/ViewMoreBtnAnchorSection";
import ViewMoreBtnParagraph from "./js/modules/ViewMoreBtnParagraph";

// Init Objects from Classes
const mobileMenu = new MobileMenu();
const videoPlayer = new VideoPlayer();
const imageSlider = new ImageSlider();
const textMediaSlider = new TextMediaSlider();
const smoothScrolling = new SmoothScrolling();
const homeViewMoreBtn = new HomeViewMoreBtn();
const viewMoreBtn = new ViewMoreBtn();
const scrollHideHeader = new ScrollHideHeader();
const ajaxNews = new AjaxNews();
const viewMoreBtnAnchorSection = new ViewMoreBtnAnchorSection();
const viewMoreBtnParagraph = new ViewMoreBtnParagraph();

// LazyLoading
import lozad from "lozad";
const lazyLoadObserver = lozad(".lozad", {
  rootMargin: "500px 0px", // syntax similar to that of CSS Margin
  threshold: 0, // ratio of element convergence
  loaded: function (el) {
    el.classList.add("loaded");
    el.classList.remove("lozad");
  },
});
lazyLoadObserver.observe();

// Small Functions
// Fix Transitions Bug on load
window.onload = function () {
  document.querySelector("body").classList.remove("preload");
};
