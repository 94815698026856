import ScrollLock from "./ScrollLock.js";

class MobileMenu {
  constructor() {
    this.menu = document.querySelector(".header__nav");
    this.toggleMenuBtn = document.querySelector(
      ".header__top-bar__menu-trigger"
    );
    this.burgerIcon = document.querySelector(".header__burger");
    this.header = document.querySelector(".header");

    this.subMenuTriggers = document.querySelectorAll(
      ".menu-item-has-children__trigger"
    );
    this.subMenus = document.querySelectorAll(".sub-menu");

    this.body = document.querySelector("body");

    this.scrollLock = new ScrollLock();

    this.setInitialAriaStateSubMenus();
    this.events();
  }

  events() {
    this.toggleMenuBtn.addEventListener("click", () => this.toggleMenu());

    this.subMenuTriggers.forEach((trigger) => {
      trigger.addEventListener("click", (e) => this.toggleSubMenus(e));
    });

    // Add event listener to the document to close the menu when clicking outside the header
    document.addEventListener("click", (e) => this.closeMenuOnOutsideClick(e));
  }

  toggleMenu() {
    this.header.classList.toggle("is-open");
    this.menu.classList.toggle("is-active");
    this.burgerIcon.classList.toggle("is-active");

    // Mobile only: Lock/unlock scroll
    if (window.innerWidth <= 992) {
      // Assuming 992px is your mobile breakpoint
      if (this.header.classList.contains("is-open")) {
        this.scrollLock.lock();
      } else {
        this.scrollLock.unlock();
      }
    }
  }

  toggleSubMenus(e) {
    const parentEl = e.target.closest(".menu-item-has-children");
    const subMenu = parentEl.querySelector(".sub-menu");
    parentEl.classList.toggle("is-active");
    subMenu.classList.toggle("active");

    let subMenuAriaExpandedState = subMenu.getAttribute("aria-expanded");
    subMenuAriaExpandedState =
      subMenuAriaExpandedState === "true" ? "false" : "true";
    subMenu.setAttribute("aria-expanded", subMenuAriaExpandedState);
  }

  setInitialAriaStateSubMenus() {
    this.subMenus.forEach((subMenu) => {
      subMenu.setAttribute("aria-expanded", "false");
    });
  }

  closeMenuOnOutsideClick(event) {
    if (
      this.header.classList.contains("is-open") &&
      !this.header.contains(event.target)
    ) {
      this.toggleMenu();
    }
  }
}

export default MobileMenu;
