// core version + navigation, pagination modules:
import Swiper, { Navigation } from "swiper";

class ImageSlider {
  constructor() {
    this.init();
  }

  init() {
    const sliders = document.querySelectorAll(".image-slider");

    if (sliders.length > 0) {
      sliders.forEach((element) => {
        this.initSwiper(element);
      });
    }
  }

  initSwiper(element) {
    const swiper = new Swiper(element, {
      modules: [Navigation],
      direction: "horizontal",
      slidesPerView: 1,
      loop: true,
      speed: 600,
      autoHeight: true,
      draggable: true,
      grabCursor: true,
      keyboard: true,
      navigation: {
        prevEl: element.querySelector(".swiper-button-prev"),
        nextEl: element.querySelector(".swiper-button-next"),
      },
      observer: true,
      observeParents: true,
    });
  }
}

export default ImageSlider;
