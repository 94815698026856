class ViewMoreBtn {
  constructor() {
    this.viewMoreButtons = document.querySelectorAll(
      ".anchor-section__item__headline"
    );

    if (this.viewMoreButtons) {
      this.events();
    }
  }

  events() {
    this.viewMoreButtons.forEach((button) => {
      button.addEventListener("click", function () {
        const container = this.closest("li.anchor-section__item");
        const textContent = container.querySelector("ul.anchor-section__item");

        if (!textContent.style.height || textContent.style.height == "0px") {
          textContent.style.height = textContent.scrollHeight + "px";
          container.classList.add("expanded");
        } else {
          textContent.style.height = "0px";
          container.classList.remove("expanded");
        }
        this.querySelector("svg").classList.toggle("rotated");
      });
    });
  }
}

export default ViewMoreBtn;
