class ScrollLock {
  constructor() {
    this.scrollPosition = 0;
    this.bodyElement = document.body;
    this.isLocked = false;
  }

  lock() {
    if (this.isLocked) return;

    this.scrollPosition = window.pageYOffset;
    this.bodyElement.style.overflow = "hidden";
    this.bodyElement.style.position = "fixed";
    this.bodyElement.style.top = `-${this.scrollPosition}px`;
    this.bodyElement.style.width = "100%";

    // Prevent content shift due to scrollbar disappearance
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    this.bodyElement.style.setProperty(
      "--scrollbar-width",
      `${scrollbarWidth}px`
    );

    this.isLocked = true;
  }

  unlock() {
    if (!this.isLocked) return;

    this.bodyElement.style.removeProperty("overflow");
    this.bodyElement.style.removeProperty("position");
    this.bodyElement.style.removeProperty("top");
    this.bodyElement.style.removeProperty("width");
    this.bodyElement.style.removeProperty("--scrollbar-width");
    window.scrollTo(0, this.scrollPosition);

    this.isLocked = false;
  }
}

export default ScrollLock;
