class HomeViewMoreBtn {
  constructor() {
    this.viewMoreButtons = document.querySelectorAll(".view-more-btn");

    if (this.viewMoreButtons) {
      this.events();
    }
  }

  events() {
    this.viewMoreButtons.forEach((button) => {
      button.addEventListener("click", function () {
        const viewMoreContainer = this.closest(".view-more");
        const truncateText = viewMoreContainer.querySelector(".truncate-text");

        if (truncateText.classList.contains("expanded")) {
          truncateText.classList.remove("expanded");
          truncateText.textContent =
            truncateText.getAttribute("data-less-text");
          this.querySelector("span").textContent =
            button.getAttribute("data-more");
        } else {
          truncateText.classList.add("expanded");
          truncateText.textContent =
            truncateText.getAttribute("data-full-text");
          this.querySelector("span").textContent =
            button.getAttribute("data-less");
        }

        this.querySelector("svg").classList.toggle("rotated");
      });
    });
  }
}

export default HomeViewMoreBtn;
