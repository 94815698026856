class ViewMoreBtnAnchorSection {
  constructor() {
    this.paragraphViewMoreButtons = document.querySelectorAll(
      ".paragraph-view-more-btn"
    );

    if (this.paragraphViewMoreButtons) {
      this.events();
    }
  }

  events() {
    this.paragraphViewMoreButtons.forEach((button) => {
      button.addEventListener("click", function () {
        const viewMoreContainer = this.closest(".paragraph-view-more");

        if (viewMoreContainer.classList.contains("expanded")) {
          viewMoreContainer.classList.remove("expanded");
          this.querySelector("span").textContent =
            button.getAttribute("data-more");
        } else {
          viewMoreContainer.classList.add("expanded");
          this.querySelector("span").textContent =
            button.getAttribute("data-less");
        }

        this.querySelector("svg").classList.toggle("rotated");
      });
    });
  }
}

export default ViewMoreBtnAnchorSection;
